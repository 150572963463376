import {useState, useEffect} from "react"; 

//import logo from './logo.svg';
import './App.css';
import hero from './img/hero-img.svg';
import logo from './img/WordInk.png';
import team1 from './img/team-1.jpg'
import team2 from './img/team-2.jpg'
import team3 from './img/team-3.jpg'
import team4 from './img/team-4.jpg'
import client1 from './img/client-1.png'
import client2 from './img/client-2.png'
import client3 from './img/client-3.png'
import client4 from './img/client-4.png'
import client5 from './img/client-5.png'
import client6 from './img/client-6.png'
import aboutImg2 from "./img/about-extra-2.svg";
import Typewriter from 'typewriter-effect';
import AOS from 'aos';

function App() {
  
  useEffect(() => {
    AOS.init();
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isTopBarHidden, setIsTopBarHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
      // Adjust the threshold as needed
      setIsTopBarHidden(scrollTop > 100); 
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="main-container">
      <section id="topbar" className={` ${isTopBarHidden ? 'hidden d-flex align-items-center ' : ''}`}>
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-envelope d-flex align-items-center">hello@wordink.net</i>
            <i class="bi bi-phone d-flex align-items-center ms-4"><span>+1 (917)830-5456</span></i>
          </div>
          <div class="social-links d-none d-md-flex align-items-center">
            <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
            <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
            <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
  </section>
     <header id="header" className={` ${isScrolled ? 'scrolled d-flex align-items-center' : ''}`}>
      <div className="container d-flex justify-content-between">

      <div className="logo">
      
         <a href="#"><img src={logo} alt="" className="img-fluid"/></a> 
      </div>

      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link scrollto " href="#pricing">Pricing</a></li>
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>
  <section id="hero" className="clearfix">
    <div className="container hero-section" data-aos="fade-up">
    <div class="hero-img" data-aos="zoom-out" data-aos-delay="200">
    <img src={hero} alt="" class="img-fluid"/>
    </div>
      <div class="hero-info"  data-aos="zoom-in" data-aos-delay="100">
      <Typewriter
          options={{
            loop: true, // Set loop option to true
          }}
          onInit={(typewriter) => {
            typewriter.typeString('<h2>Welcome to <span> WordInk </span></h2>')
              .callFunction(() => {
                console.log('String typed out!');
              })
              .pauseFor(2500).start();
              
          }}
        />
        <p>One stop solution to your data migration, integraion and implementation services</p>

        <div>
          <a href="#about" className="btn-get-started scrollto">Get Started</a>
          <a href="#services" className="btn-services scrollto">Our Services</a>
        </div>
      </div>

    </div>
    
  </section>
    <main id='main'>
    <section id="clients" className="clients section-bg">
      <div className="container aos-init aos-animate" data-aos="zoom-in">

        <div className="row">

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={client1} className="img-fluid" alt="client1"/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={client2}  className="img-fluid" alt="client2"/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={client3} className="img-fluid" alt="client3"/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={client4}  cclassName="img-fluid" alt="client4"/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={client5}  className="img-fluid" alt="client5"/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={client6}  className="img-fluid" alt="client6"/>
          </div>

        </div>

      </div>
    </section>

    <section id="about">
      <div className="container" data-aos="fade-up">

        <header className="section-title">
          <h2>About Us</h2>
          <h3>Leaders in<span> Large Data </span>Migration</h3>
        </header>
        <div class="row about-container">

            <div class="col-lg-6 content order-lg-1 order-2">
            <h4>Innovative Data Migration Solutions</h4>
            <p>
            Transform your data management when our experts handle your data migration. We provide fast and reliable solutions to move your large datasets seamlessly, ensuring the success of your projects using leading Data Migration Services.
            </p>        
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon"><i class="bi bi-send-check"></i></div>
                <h4 class="title"><a href="">Seamless Data Transfer</a></h4>
                <p class="description">We provide seamless data transfer solutions tailored to your business needs.</p>
              </div>

              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon"><i class="bi bi-currency-exchange"></i></div>
                <h4 class="title"><a href="">Money-Back Guarantee</a></h4>
                <p class="description">We offer a 30-day money-back guarantee to ensure your satisfaction.</p>
              </div>

              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon"><i class="bi bi-headset"></i></div>
                <h4 class="title"><a href="">24/7 Customer Support</a></h4>
                <p class="description">Our dedicated support team is available 24/7 to assist you during your data migration process.</p>
              </div>

            </div>

            <div class="col-lg-6 background order-lg-2" data-aos="zoom-in">
              <img src={aboutImg2}  class="img-fluid" alt=""/>
            </div>
       </div>

       
      

      </div>
    </section>
    <section id="counts" className="counts">
      <div className="container aos-init aos-animate" data-aos="fade-up">
      <header class="section-title">
          {/* <h2>Services</h2> */}
          <h3>Data<span> Migration </span> Stats</h3>
          <p>Explore our impressive data migration statistics and achievements in the field.</p>
        </header>


        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="count-box">
              <i className="bi bi-emoji-grin"></i>
              <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="0" class="purecounter">232</span>
              <p>Happy Clients</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div class="count-box">
              <i class="bi bi-sliders"></i>
              <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="0" class="purecounter">521</span>
              <p>Data Migrated</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div class="count-box">
              <i class="bi bi-headset"></i>
              <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="0" class="purecounter">1463</span>
              <p>Hours Of Support</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div class="count-box">
              <i class="bi bi-people"></i>
              <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="0" class="purecounter">15</span>
              <p>Hard Workers</p>
            </div>
          </div>

        </div>

      </div>
    </section>

    
    <section id="services" class="section-bg">
      <div class="container" data-aos="fade-up">

        <header class="section-title">
          <h2>Services</h2>
          <h3>Here are<span> Services </span>We Offer</h3>
          <p>Optimize your data management with our well curated range of solutions designed to meet your migration needs like never before.</p>
        </header>

        <div class="row justify-content-center">

          <div class="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
            <div class="box">
              <div class="icon"><i class="bi bi-database-check" ></i></div>
              <h4 class="title"><a href="">HIGH QUALITY DATA MIGRATION</a></h4>
              <p class="description">Exceptional reuslts become a reality when your data migration problems meet our premium solutions.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-5" >
            <div class="box">
              <div class="icon"><i class="bi bi-shield-check" ></i></div>
              <h4 class="title"><a href="">DATA PRIVACY ASSURANCE</a></h4>
              <p class="description">We prioritize data privacy, from company to client data, down to Personal Identifiable Information. Your data is safe with us, and we don't keep any traffic logs.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
            <div class="box">
              <div class="icon"><i class="bi bi-bar-chart" ></i></div>
              <h4 class="title"><a href="">SEAMLESS DATA TRANSITION</a></h4>
              <p class="description">Expert guidance ensures a smooth and hassle-free data transition process.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
            <div class="box">
              <div class="icon"><i class="bi bi-chevron-contract" ></i></div>
              <h4 class="title"><a href="">EFFICIENT DATA TRANSFER</a></h4>
              <p class="description">Migrate your data efficiently using our tools and resources.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100">
            <div class="box">
              <div class="icon"><i class="bi bi-fingerprint" ></i></div>
              <h4 class="title"><a href="">DATA SECURITY MEASURES</a></h4>
              <p class="description">The safety of your data during migration is definite with our robust security measures.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="100" >
            <div class="box">
              <div class="icon"><i class="bi bi-wallet2" ></i></div>
              <h4 class="title"><a href="">TRANSPARENT PRICING</a></h4>
              <p class="description">Our transparent pricing ensures you know what you're paying for. No hidden charges!</p>
            </div>
          </div>

        </div>

      </div>
    </section>

   

   
    <section id="pricing" class="pricing">
      <div class="container aos-init aos-animate" data-aos="fade-up" >
        
        <div class="section-title">
          <h2>Pricing</h2>
          <h3>Our<span> Data Migration </span>Plans</h3>
          <p>Choose from our well tailored data migration plans and packages carefully curated to meet your data transfer needs.</p>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="box">
              <h3>Basic</h3>
              <h4><sup>$</sup>39.99<span> / month</span></h4>
              <ul>
                <li>10GB Data Transfer</li>
                <li>Data Migration Services</li>
                <li>API Integration</li>
                <li class="na">Basic Support</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <div class="box featured">
              <h3>Standard</h3>
              <h4><sup>$</sup>49.99<span> / month</span></h4>
              <ul>
                <li>20GB DatTransfer</li>
                <li>Data Migration Services</li>
                <li>API Integration</li>
                <li>Standard Support</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div class="box">
              <h3>Premium</h3>
              <h4><sup>$</sup>69.99<span> / month</span></h4>
              <ul>
                <li>50GB Data Transfer</li>
                <li>Data Migration Services</li>
                <li>API Integration</li>
                <li>Premium Support</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300" >
            <div class="box">
              <span class="advanced">Advanced</span>
              <h3>Ultimate</h3>
              <h4><sup>$</sup>99.99<span> / month</span></h4>
              <ul>
                <li>100GB Data Transfer</li>
                <li>Data Migration Services</li>
                <li>API Integration</li>
                <li>Advanced Support</li>
              </ul>
              <div class="btn-wrap">
                <a href="#" class="btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    
    <section id="team" className='team section-bg'>
      <div class="container" >
        <div class="section-title">
          <h2>Team</h2>
          <h3>Our Hardworking <span>Team</span></h3>
          <p>Meet our talented team of professionals dedicated to ensuring seamless data transfers.</p>
        </div>

        <div class="row">

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="member">
              <div class="member-img">
              <img src={team1} class="img-fluid" alt=""/> 
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <div class="member">
              <div class="member-img">
                <img src={team4} class="img-fluid" alt=""/> 
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Lead Data Migration Officer</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
            <div class="member">
              <div class="member-img">
                <img src={team3} className="img-fluid" alt=""/> 
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
            <div class="member">
              <div class="member-img">
                <img src={team2} class="img-fluid" alt=""/> 
                <div class="social">
                  <a href=""><i class="bi bi-twitter"></i></a>
                  <a href=""><i class="bi bi-facebook"></i></a>
                  <a href=""><i class="bi bi-instagram"></i></a>
                  <a href=""><i class="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div class="member-info">
                <h4>Davidson Jepson</h4>
                <span>Data Engineer</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>


    <section id="contact" class="contact">
      <div class="container aos-init aos-animate" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
          <h3><span>Contact Us</span></h3>
          
        </div>

        <div class="row aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-6">
            <div class="info-box mb-4">
              <i class="bi bi-map"></i>
              <h3>Our Address</h3>
              <p>A108 Adam Street, New York, NY 535022</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="info-box  mb-4">
              <i class="bi bi-envelope"></i>
              <h3>Email Us</h3>
              <p>hello@Wordink.net</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="info-box  mb-4">
              <i class="bi bi-phone"></i>
              <h3>Call Us</h3>
              <p>+1 (917)830-5456</p>
            </div>
          </div>

        </div>
      </div>
    </section>
    

    </main>
    <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-info">
          <div className="logo-footer">
      
              <a href="#"><img src={logo} alt="" className="img-fluid"/></a> 
          </div>
            <p>Leading the charge in large data migration, dedicated to delivering cutting-edge solutions for small, medium and large-scale businesses.</p>
          </div>

          <div class="col-lg-4 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">About us</a></li>
              <li><a href="#">Services</a></li>
              <li><a href="#">Terms of service</a></li>
              <li><a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              A108 Adam Street
              New York, NY 535022
              United States.
            </p>
              <p><strong> Phone:</strong>+1 (917)830-5456 </p>
              <p><strong> Email:</strong> hello@wordink.net </p>
            

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
              <a href="#" class="youtube"><i class="bi bi-youtube"></i></a>
              
            </div>

          </div>

       

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong>Wordink</strong>. All Rights Reserved
      </div>
    </div>
  </footer>
  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  
  

   

</div>
  );
}

export default App;
